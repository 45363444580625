import React, { useEffect } from 'react';
import styles from './vacancy.module.scss';
import { useVacancies } from '../lib/useVacancies';
import VacancyItem from './VacancyItem';

function Vacancy() {
  const { vacanciesLoading, vacanciesError, vacancies, getVacancies } =
    useVacancies();

  useEffect(() => {
    getVacancies();
  }, []);

  if (vacanciesLoading || vacanciesError || !vacancies) {
    return (
      <>
        <h1 className={styles.vacancyTitle}>Вакансии</h1>
        <p className={styles.vacancySubtitle}>
          В данный момент вакансии отсутствуют
        </p>
      </>
    );
  }

  return (
    <>
      <h1 className={styles.vacancyTitle}>Вакансии</h1>
      <p className={styles.vacancySubtitle}>
        В данный момент открыто {vacancies.length} вакансии
      </p>
      {vacancies.map((vacancy) => (
        <VacancyItem key={vacancy.id} vacancy={vacancy} />
      ))}
    </>
  );
}

export { Vacancy };
