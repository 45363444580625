import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MainSlider.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

import { useWindowSize } from 'shared/lib/windowSizeChecker';

import { MainBannerSkellaton } from 'shared/ui/Skelletons/MainBanner';

function MainSlider({ banners, loading, error }) {
  const windowSize = useWindowSize();

  // Этим ключом выбираю нужную ссылку на баннер
  const [key, setKey] = useState('');

  // Меняю ключ в зависимости от размеров экрана
  useEffect(() => {
    if (!loading && banners.length) {
      if (windowSize < 600) {
        setKey('image360x512');
      } else if (windowSize < 940) {
        setKey('image768x512');
      } else if (windowSize < 1280) {
        setKey('image1024x512');
      } else if (windowSize < 1700) {
        setKey('image1286x512');
      } else {
        setKey('image1840x512');
      }
    }
  }, [windowSize, loading, banners]);

  if (error) {
    return null;
  }

  if (loading) {
    return <MainBannerSkellaton />;
  }

  if (banners) {
    return (
      <div className="mainSlider">
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 4000 }}
          slidesPerView={1}
          loop={true}
          className="mainBannerSlider"
        >
          {banners?.map((banner) => (
            <SwiperSlide key={banner.id}>
              <Link to={banner.slug ? `/news/${banner.slug}` : '#'}>
                <img src={banner[key]} alt={banner.name} loading="lazy" />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
}

export { MainSlider };
