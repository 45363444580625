import axios from 'axios';
import { urls } from '../../api/Config/usrlsConfig';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};

const $api = axios.create({
  withCredentials: true,
  baseURL: urls.baseUrl,
});

$api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._isRetry &&
      !isRefreshing
    ) {
      originalRequest._isRetry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const response = await $api.get(urls.checkAuth);
          localStorage.setItem('token', response.data.access_token);

          processQueue(null, response.data.access_token);

          isRefreshing = false;

          return $api.request(originalRequest);
        } catch (err) {
          processQueue(err, null);
          isRefreshing = false;

          throw err;
        }
      }

      // Если токен уже обновляется, ждем завершения
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return $api.request(originalRequest);
      });
    }

    throw error;
  },
);

export default $api;
